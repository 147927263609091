<div class="row d-flex justify-content-between">
    <div class="input-group input-group-sm  mb-2 item d-flex justify-content-start">
        <div class="input-group-append" *ngIf="id > 0">
            <button class="btn btn-app btn-accent" type="button" (click)='navigateTo()'>
                <i class="fas fa-table"></i>
                Tabellino di gara
            </button>
        </div>
    </div>
    <div class="input-group input-group-sm mb-2 item add-item">
        <div class="input-group-append">
            <button class="btn btn-app" type="button" (click)='saveItem()'>
                <i class="fas fa-table"></i>
                Salva
            </button>
        </div>
    </div>
</div>

<!-- {{single|json}} -->
<div class="pr-3 pl-3 mb-5">
    <div class="table-responsive">
        <accordion>

            <accordion-group heading="INFO" [panelClass]="varCustomClass" isOpen="true">
                <table class="table table-hover table-sm table-real-match" id="tabInfo">
                    <thead class="thead-default">
                        <tr>
                            <td class="w-25">Descrizione</td>
                            <td>Valore</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="id > 0">
                            <td>ID</td>
                            <td>
                                <input class="form-control form-control-sm" type="number" [(ngModel)]="single['id']"
                                    [id]="'id'" [disabled]="true">
                            </td>
                        </tr>
                        <tr *ngIf="id == 0">
                            <td>Competizione</td>
                            <td>
                                <select class="form-select" id="inputGroupSelect02" [(ngModel)]="single['real_competition_id']" (change)="selectCompetition($event)">
                                    <option value="-1"> -- Selezione la competizione</option>
                                    <option *ngFor="let competition of competitions" [value]="competition.id" [selected]="competition.id == single['real_competition_id']" > {{competition.description}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="id == 0">
                            <td>Club Casa</td>
                            <td>
                                <select class="form-select" id="inputGroupClubList1" [(ngModel)]="single['realclub1_id']" >
                                    <option value="-1"> -- Selezione il Club</option>
                                    <option *ngFor="let rclub of realClubList" [value]="rclub.id" [selected]="rclub.id == single['realclub1_id']" > {{rclub.common_mame}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="id == 0">
                            <td>Club Ospite</td>
                            <td>
                                <select class="form-select" id="inputGroupClubList2" [(ngModel)]="single['realclub2_id']" >
                                    <option value="-1"> -- Selezione il Club</option>
                                    <option *ngFor="let rclub of realClubList" [value]="rclub.id" [selected]="rclub.id == single['realclub2_id']" > {{rclub.common_mame}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Giornata</td>
                            <td>
                                <input class="form-control form-control-sm" type="number"
                                    [(ngModel)]="single['matchday']" [id]="'matchday'">
                            </td>
                        </tr>
                        <tr>
                            <td>Data</td>
                            <td>
                                <input class="form-control form-control-sm" type="text" bsDatepicker [(ngModel)]="matchDate"  id="utcDate"
                                [bsConfig]="{withTimepicker: true, rangeInputFormat : 'DD-MM-YYYY, HH:mm:ss', dateInputFormat: 'DD-MM-YYYY, HH:mm'}">
                            </td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>
                                <!-- <input class="form-control form-control-sm" type="text" [(ngModel)]="single['status']" [id]="'status'"> -->
                                <select  class="form-select" id="inputGroupStatus" [(ngModel)]="single['status']" [id]="'status'">
                                    <option value="-1" >Status</option>
                                    <option [value]="'FINISHED'" [selected]="single['status'] == 'FINISHED'">FINITA</option>
                                    <option [value]="'POSTPONED'" [selected]="single['status'] == 'POSTPONED'">POSTICIPATA</option>
                                    <option [value]="'SCHEDULED'" [selected]="single['status'] == 'SCHEDULED'">SCHEDULATA</option>
                                </select>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td>Minuti</td>
                            <td>
                                <input class="form-control form-control-sm" type="number" [(ngModel)]="single['minute']"
                                    [id]="'minute'">
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td>Presenze</td>
                            <td>
                                <input class="form-control form-control-sm" type="number"
                                    [(ngModel)]="single['attendance']" [id]="'attendance'">
                            </td>
                        </tr> -->
                        <tr>
                            <td>Luogo</td>
                            <td>
                                <input class="form-control form-control-sm" type="text" [(ngModel)]="single['venue']"
                                    [id]="'venue'">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row d-flex justify-content-between">
                    <div class="input-group input-group-sm  mb-2 item d-flex justify-content-start">
                        <div class="input-group-append" *ngIf="id > 0">
                            <!-- <a class="btn btn-app btn-accent" type="button" >
                                <i class="fas fa-table"></i>
                                info
                            </a> -->
                        </div>
                    </div>
                    <div class="input-group input-group-sm mb-2 item add-item">
                        <div class="input-group-append">
                            <button class="btn btn-app" type="button" (click)='saveItem(true)'>
                                <i class="fas fa-table"></i>
                                Salva dati Info
                            </button>
                        </div>
                    </div>
                </div>
            </accordion-group>

            <accordion-group heading="Risultato" [panelClass]="varCustomClass">
                <table class="table table-hover table-sm table-real-match" id="tabRisultato">
                    <thead class="thead-default">
                        <tr>
                            <td class="w-25">Descrizione</td>
                            <td>Valore</td>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr>
                            <td>Squadra vincente</td>
                            <td>
                                <input class="form-control form-control-sm" type="text"
                                    [(ngModel)]="single['score_winner']" [id]="'score_winner'">
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td>Durata</td>
                            <td>
                                <input class="form-control form-control-sm" type="text"
                                    [(ngModel)]="single['score_duration']" [id]="'score_duration'">
                            </td>
                        </tr> -->
                        <tr>
                            <td>Risultato Finale</td>
                            <td>
                                <!-- <input class="form-control form-control-sm" type="number" [(ngModel)]="single['score_fullTime']" [id]="'score_fullTime'"> -->
                                <strong>{{single['score_fullTime']?.homeTeam}}{{single['score_fullTime']?.home}} -
                                    {{single['score_fullTime']?.awayTeam}} {{single['score_fullTime']?.away}}</strong>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td>Risultato 1° Tempo</td>
                            <td>
                                <!-- <input class="form-control form-control-sm" type="text" [(ngModel)]="single['score_halfTime']" [id]="'score_halfTime'">   //-/->
                                {{single['score_halfTime']?.homeTeam}}{{single['score_halfTime']?.home}} -
                                {{single['score_halfTime']?.awayTeam}} {{single['score_halfTime']?.away}}
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td>Risultato Supplementari</td>
                            <td>
                                <!-- <input class="form-control form-control-sm" type="text" [(ngModel)]="single['score_extraTime']" [id]="'score_extraTime'"> -/->
                                {{single['score_extraTime']?.homeTeam}}{{single['score_extraTime']?.home}} -
                                {{single['score_extraTime']?.awayTeam}} {{single['score_extraTime']?.away}}
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td>Risultato Rigori</td>
                            <td>
                                <!-- <input class="form-control form-control-sm" type="text" [(ngModel)]="single['score_penalties']" [id]="'score_penalties'"> -/->
                                {{single['score_penalties']?.homeTeam}}{{single['score_penalties']?.home}} -
                                {{single['score_penalties']?.awayTeam}} {{single['score_penalties']?.away}}
                            </td>
                        </tr> -->
                        <tr>
                            <td class="vtop"><i class="fas fa-futbol fa-fw"></i> Goal</td>
                            <td>
                                <span *ngFor="let item of single.goals " class="goal-list">
                                    <!--{{item|json}}<br>-->
                                    {{item['minute']}}'
                                    <span *ngIf="item['extraTime']">+{{item['extraTime']}}' </span>
                                    <span *ngIf="item['type'] == 'PENALTY'">(<span class="penalty">R</span>) - </span>
                                    <strong>{{item['scorer']['name']}}</strong> ({{item['team']['name']}})

                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td class="vtop"><i class="fas fa-address-book fa-fw"></i> Cartellini</td>
                            <td>
                                <span *ngFor="let item of toArray(single?.bookings)" class="card-list">
                                    <!-- <pre>{{item|json}}</pre> -->
                                    <!--{{item.key}}:{{item.value}}  {{item.card}}, Minuto {{item['minute']}}-->
                                    <span *ngIf="item['card'] == 'YELLOW_CARD'"><i
                                            class="fas fa-address-book fa-fw yellow-card"></i></span>
                                    <span *ngIf="item['card'] == 'RED_CARD'"><i
                                            class="fas fa-address-book fa-fw red-card"></i></span>
                                    <span *ngIf="item['card'] == 'YELLOW_RED_CARD'"><i
                                            class="fas fa-address-book fa-fw yellow-card"></i><i
                                            class="fas fa-address-book fa-fw red-card"></i></span>
                                    {{item['minute']}}'
                                    <span *ngIf="item['extraTime']">+{{item['extraTime']}}' - </span>
                                    <strong>{{item['player']['name']}}</strong> ({{item['team']['name']}})
                                </span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </accordion-group>

            <accordion-group heading="Formazioni" [panelClass]="varCustomClass">
                <table class="table table-hover table-sm table-real-match" id="tabFormazioni">
                    <thead class="thead-default">
                        <tr>
                            <td class="w-25">Club</td>
                            <td colspan="2">Giocatori</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="vtop">
                                Casa:<br>
                                <h3>{{homeClub}}</h3>
                                <select  class="form-select" id="inputGroupSelect01" [(ngModel)]="single['tactic_module1_id']" >
                                    <option value="-1" >Modulo di gioco</option>
                                    <option *ngFor="let tactic of tacticModules" [value]="tactic.id" [selected]="tactic.id == single['tactic_module1_id']" > {{tactic.description}}</option>
                                </select>
                            </td>
                            <td class="vtop">
                                <h4>Titolari</h4>
                                <span *ngFor="let item of single['realclub1_lineup']" class="player-info">
                                    <button class="btn btn-manage-player btn-sm btn-remove" type="button"
                                        (click)="removePlayer(item, 'realclub1_lineup')" title="Rimuovi titolare">
                                        <i class="fas fa-user-minus"></i>
                                    </button>
                                    <span class="shirt-number" class="{{item['ruolo']}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                            <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                                        </svg>
                                        <span class="number">{{item['shirtNumber']}}</span>
                                    </span>
                                    {{item['calciatore']}}
                                    <span *ngIf="item['ruolo']">({{item['ruolo']}}) </span>
                                    <!-- <br>{{item|json}} -->
                                    <br>
                                </span>
                                <br>
                                <h4>Panchina</h4>
                                <span *ngFor="let item of single['realclub1_bench']" class="player-info">
                                    <button class="btn btn-manage-player btn-sm btn-remove" type="button"
                                        (click)="removePlayer(item, 'realclub1_bench')" title="Rimuovi sostituto">
                                        <i class="fas fa-user-minus"></i>
                                    </button>
                                    <span class="shirt-number" class="{{item['ruolo']}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                            <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                                        </svg>
                                        <span class="number">{{item['shirtNumber']}}</span>
                                    </span>
                                    {{item['calciatore']}}
                                    <span *ngIf="item['ruolo']">({{item['ruolo']}}) </span>
                                    <!-- {{item|json}} -->
                                    <br>
                                </span>
                            </td>
                            <td class="vtop">
                                <h4>A disposizione</h4>
                                <span *ngFor="let rp1 of rplayer1" class="player-info">
                                    <!-- ctrl_convocato{{rp1.ctrl_convocato|json}} -->
                                    <span *ngIf="!rp1.ctrl_convocato">
                                        <button class="btn btn-manage-player btn-sm btn-add add-starter" type="button"
                                            (click)="addPlayer(rp1, 'realclub1_lineup', single['tactic_module1_id'])" title="Aggiungi come titolare">
                                            <strong>+T</strong>
                                        </button>
                                        <button class="btn btn-manage-player btn-sm btn-add add-substitute" type="button"
                                            (click)="addPlayer(rp1, 'realclub1_bench', null)" title="Aggiungi come sostituto">
                                            <strong>+P</strong>
                                        </button>
                                        <span class="shirt-number" class="{{rp1['ruolo']}}">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                                <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                                              </svg>
                                            <span class="number">{{rp1['shirtNumber']}}</span>
                                        </span>
                                        {{rp1['calciatore']}}
                                        ({{rp1['ruolo']}})
                                    </span>
                                </span>

                            </td>
                        </tr>

                        <tr>
                            <td class="vtop">
                                Ospite:<br>
                                <h3>{{visitorClub}}</h3>
                                <select  class="form-select" id="inputGroupSelect02" [(ngModel)]="single['tactic_module2_id']" >
                                    <option value="-1" >Modulo di gioco</option>
                                    <option *ngFor="let tactic of tacticModules" [value]="tactic.id" [selected]="tactic.id == single['tactic_module2_id']" > {{tactic.description}}</option>
                                </select>
                            </td>
                            <td class="vtop">
                                <h4>Titolari</h4>
                                <span *ngFor="let item of single['realclub2_lineup']" class="player-info">
                                    <button class="btn btn-manage-player btn-sm btn-remove" type="button"
                                        (click)="removePlayer(item, 'realclub2_lineup')" title="Rimuovi titolare">
                                        <i class="fas fa-user-minus"></i>
                                    </button>
                                    <span class="shirt-number" class="{{item['ruolo']}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                            <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                                        </svg>
                                        <span class="number">{{item['shirtNumber']}}</span>
                                    </span>
                                    {{item['calciatore']}}
                                    <span *ngIf="item['ruolo']">({{item['ruolo']}}) </span>
                                    <!-- <br>{{item|json}} -->
                                    <br>
                                </span>
                                <br>
                                <h4>Panchina</h4>
                                <span *ngFor="let item of single['realclub2_bench']" class="player-info">
                                    <button class="btn btn-manage-player btn-sm btn-remove" type="button"
                                        (click)="removePlayer(item, 'realclub2_bench')" title="Rimuovi sostituto">
                                        <i class="fas fa-user-minus"></i>
                                    </button>
                                    <span class="shirt-number" class="{{item['ruolo']}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                            <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                                        </svg>
                                        <span class="number">{{item['shirtNumber']}}</span>
                                    </span>
                                    {{item['calciatore']}}
                                    <span *ngIf="item['ruolo']">({{item['ruolo']}}) </span>
                                    <!-- {{item|json}} -->
                                    <br>
                                </span>
                            </td>
                            <td class="vtop">
                                <h4>A disposizione</h4>
                                <span *ngFor="let rp2 of rplayer2" class="player-info">
                                    <span *ngIf="!rp2.ctrl_convocato">
                                        <button class="btn btn-manage-player btn-sm btn-add add-starter" type="button"
                                            (click)="addPlayer(rp2, 'realclub2_lineup', single['tactic_module2_id'])" title="Aggiungi come titolare">
                                            <strong>+T</strong>
                                        </button>
                                        <button class="btn btn-manage-player btn-sm btn-add add-substitute"
                                            type="button" (click)="addPlayer(rp2, 'realclub2_bench', null)"
                                            title="Aggiungi come sostituto">
                                            <strong>+P</strong>
                                        </button>
                                        <span class="shirt-number" class="{{rp2['ruolo']}}">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                                <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                                              </svg>
                                            <span class="number">{{rp2['shirtNumber']}}</span>
                                        </span>
                                        {{rp2['calciatore']}}
                                        ({{rp2['ruolo']}})
                                    </span>
                                </span>

                            </td>
                        </tr>
                        <tr>
                            <td class="vtop"><i class="fas fa-sort fa-fw"></i> Sostituzioni</td>
                            <td colspan="2" class="vtop">
                                <span *ngFor="let item of single['substitutions']" class="substitutions-list">
                                    {{item['minute']}}'
                                    <strong><span *ngIf="item['extraTime']">+{{item['extraTime']}}'</span> -
                                        {{item['team']['name']}}</strong>
                                    <br>
                                    <span *ngIf="item['playerIn']"><i class="fas fa-caret-up fa-fw sub-in"></i>
                                        {{item['playerIn']['name']}}</span><br>
                                    <span *ngIf="item['playerOut']"><i class="fas fa-caret-down fa-fw sub-out"></i>
                                        {{item['playerOut']['name']}}</span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </accordion-group>
        </accordion>

    </div>

    <div class="d-flex justify-content-between">
        <div class="input-group input-group-sm  mt-2 item d-flex justify-content-start">
            <div class="input-group-append" *ngIf="id > 0">
                <button class="btn btn-app btn-accent" type="button" (click)='navigateTo()'>
                    <i class="fas fa-table"></i>
                    Tabellino di gara
                </button>
            </div>
        </div>
        <div class="input-group input-group-sm mt-2 item add-item">
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)='saveItem()'>
                    <i class="fas fa-save"></i>
                    Salva
                </button>
            </div>
        </div>
    </div>

</div>
<app-alertbox #alertbox1 [alerts]=""></app-alertbox>